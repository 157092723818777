body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
    position: relative;
    text-align: center;
    width: 100%;
}

/* based on https://www.w3schools.com/howto/howto_css_image_text.asp */
.centered {
    position: absolute;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
}
